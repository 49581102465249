<template>
	<div class="page-content">
		<div class="park-form">
			<el-form inline label-position="top" ref="searchForms" style="display: flex;flex-wrap: wrap">
				<!--<el-form-item label="发放日期" prop="authUserName">-->
				<!--<el-date-picker-->
				<!--clearable-->
				<!--style="width: 220px;"-->
				<!--v-model="selectDate"-->
				<!--type="daterange"-->
				<!--format="yyyy 年 MM 月 dd 日"-->
				<!--value-format="yyyy-MM-dd"-->
				<!--@change="selectedDate"-->
				<!--range-separator="至"-->
				<!--start-placeholder="开始日期"-->
				<!--end-placeholder="结束日期"-->
				<!--&gt;</el-date-picker>-->
				<!--</el-form-item>-->
				<!--<el-form-item style="margin-top: auto;">-->
				<!--<div class="fill-btn" @click="couponList">查询</div>-->

				<!--</el-form-item>-->


				<el-form-item style="margin-top: auto;margin-left: 20px;margin-right: 0;">
					<div class="fill-btn" @click="handShowAdd">新增</div>
				</el-form-item>
			</el-form>
		</div>

		<div class="table-content">
			<div class="table-top">
				<div class="table-top-text">
					<!--已选择{{selectData.length}}项-->
				</div>
			</div>
			<el-table :data="tableData" stripe v-loading="loading" @selection-change="handleSelectionChange"
				cell-class-name="text-center" header-cell-class-name="header-row-class-name">
				<el-table-column prop="price" label="代金券金额" width="100"></el-table-column>
				<el-table-column prop="integral" label="所需积分" min-width="20%"></el-table-column>
				<el-table-column prop="restrict" label="限制金额" min-width="20%"></el-table-column>

				<el-table-column width="60" label="状态">
					<template slot-scope="{row}">
						<div class="btn-content">
							<sapn v-if="row.status == 1" type="primary" round>正常</sapn>
							<sapn v-if="row.status == 2" type="warning" round>关闭</sapn>
						</div>
					</template>
				</el-table-column>
				<el-table-column width="60" label="类型">
					<template slot-scope="{row}">
						<div class="btn-content">
							<sapn v-if="row.type == 1" type="primary" round>兑换</sapn>
							<sapn v-if="row.type == 2" type="warning" round>领取</sapn>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="note" label="名称" min-width="20%"></el-table-column>
				<el-table-column prop="created_at" label="创建日期" width="148"></el-table-column>
				<el-table-column width="220" fixed="right">
					<template slot-scope="{row}">
						<div class="btn-content">
							<el-button class="el-icon-edit-outline cur-p" type="primary" size="mini"
								@click="handEditShow(row)"></el-button>
							<el-button class="el-icon-delete cur-p" type="primary" size="mini"
							           @click="handDel(row)"></el-button>
							<el-button class=" cur-p" type="primary" size="mini" @click="userLists(row)">兑换/领取列表
							</el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination-content">
				<el-pagination background :current-page.sync="searchForm.page" :page-size="searchForm.limit"
					@current-change="searchForm.page=$event" @size-change="handleSizeChange"
					layout="total, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>

		<el-dialog :title="`${editId?'编辑':'新增'}`" :visible.sync="showEdit">
			<div style="padding: 20px;">
				<el-form ref="forms" :model="editForm" :rules="rules" label-width="120px">
					<el-form-item label="类型" prop="type">
						<el-select v-model="editForm.type" placeholder="请选状态">
							<el-option label="兑换" :value='1'></el-option>
							<el-option label="领取" :value='2'></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="无限兑换" v-if="editForm.type==1" prop="exchangeable">
						<el-select v-model="editForm.exchangeable" placeholder="请选状态">
							<el-option label="是" :value='1'></el-option>
							<el-option label="否" :value='2'></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="代金券金额" prop="price">
						<el-input type="number" v-model="editForm.price"></el-input>
					</el-form-item>
					<el-form-item label="兑换积分" v-if="editForm.type==1" prop="integral">
						<el-input type="number" v-model="editForm.integral"></el-input>
					</el-form-item>
					<el-form-item label="最低使用金额" prop="restrict">
						<el-input type="number" v-model="editForm.restrict"></el-input>
					</el-form-item>
					<el-form-item :label="`${editForm.type==1?'兑换':'领取'}后过期天数`" prop="aging">
						<el-input type="number" v-model="editForm.aging"></el-input>
					</el-form-item>
					<el-form-item label="状态" prop="status">
						<el-select v-model="editForm.status" placeholder="请选状态">
							<el-option label="开启" :value='1'></el-option>
							<el-option label="关闭" :value='2'></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="名称" prop="note">
						<el-input v-model="editForm.note"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" v-if="editId" @click="goodsSave" style="margin: 10px auto">保存</el-button>
						<el-button type="primary" v-else @click="redCreate" style="margin: 10px auto">保存
						</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
		<el-dialog title="领取列表" :width="1000" :visible.sync="showTeamList">
			<div style="padding: 20px;">

				<el-table v-loading="teamObj.loading" :data="teamList" stripe cell-class-name="text-center"
					header-cell-class-name="header-row-class-name">
					<el-table-column prop="id" label="ID" min-width="120px"></el-table-column>
					<el-table-column prop="nickname" label="用户" min-width="120px"></el-table-column>
				</el-table>
				<div class="pagination-content">
					<el-pagination background :current-page.sync="teamObj.page" :page-size="teamObj.limit"
						@current-change="selectPage($event,4)" @size-change="handleSizeChangeTeam"
						layout="total, prev, pager, next, jumper" :total="teamListTotal"></el-pagination>
				</div>
			</div>
		</el-dialog>


	</div>
</template>

<script>
	import {
		delToast,
		formVerify
	} from './../../utlis/decorators'
	import RichText from './../../components/RichText'

	export default {
		name: "tenant_account",
		props: {
			parkId: {
				type: [Number, String],
				default: null
			},
		},
		components: {
			RichText
		},
		data() {
			return {
				teamObj: {
					page: 1,
					limit: 3,
					loading:false,
					id: ''
				},
				teamListTotal: 0,
				teamList: [],
				showTeamList: false,
				selectDate: [],
				dialogFormVisible: false,
				loading: false,
				currentTop: 0,
				total: 0,
				allPrice: 0,
				showAdd: false,
				showEdit: false,
				showEditPsd: false,
				editing: false,
				searchForm: {
					page: 1,
					limit: 10,
					username: ''
				},
				form: {
					nickname: ''
				},
				editForm: {
					type: '',
					exchangeable: '',
					price: '',
					integral: '',
					restrict: '',
					aging: '',
					status: '',
					note: ''
				},
				currentId: '',
				tableData: [],
				typeList: [],
				editId: '',
				teacherAll: [],
			}
		},
		created() {
			this.couponList();
		},
		computed: {
			rules() {
				return {
					type: [{
						required: true,
						message: `请选择类型`,
						trigger: ['blur', 'change']
					}],
					exchangeable: [{
						required: true,
						message: `请选择无限兑换`,
						trigger: ['blur', 'change']
					}],
					price: [{
						required: true,
						message: `请输入代金券金额`,
						trigger: ['blur', 'change']
					}],
					integral: [{
						required: true,
						message: `请输入兑换积分`,
						trigger: ['blur', 'change']
					}],
					restrict: [{
						required: true,
						message: `请输入最低使用金额`,
						trigger: ['blur', 'change']
					}],
					aging: [{
						required: true,
						message: `请输入兑换后过期天数`,
						trigger: ['blur', 'change']
					}],
					status: [{
						required: true,
						message: `请选择状态`,
						trigger: ['blur', 'change']
					}],
					note: [{
						required: true,
						message: `请输入名称`,
						trigger: ['blur', 'change']
					}]
				}
			}
		},
		watch: {
			'searchForm.page'() {
				this.couponList();
			},
			'teamObj.page'() {
				this.getUserCoupon();
			},
		},
		methods: {
			//头像
			handleAvatarSuccess(res, file) {
				this.editForm.cover = res.url;
				this.addCourse.cover = res.url;
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/png';
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isJPG) {
					this.$message.error('上传头像图片只能是 JPG,png 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
			},
			handShowAdd() {
				this.editId = '';
				try {
					this.showEdit = true;
					this.$refs['forms'].resetFields()
					for (let i in this.editForm) {
						this.editForm[i] = '';
					}
				} catch {}
			},
			// 选择日期
			selectedDate(e) {
				if (e) {
					this.searchForm.start_time = e[0];
					this.searchForm.end_time = e[1];
					console.log(this.searchForm);
				} else {
					this.searchForm.start_time = '';
					this.searchForm.end_time = '';
				}
			},
			handEditShow(row) {
				this.editId = row.id;
				for (let i in this.editForm) {
					this.editForm[i] = row[i];
				}
				this.showEdit = true;
			},
			//页面数量改变
			handleSizeChange(val) {
				this.searchForm.page = 1;
				this.searchForm.limit = val;
			},
			//领取列表
			userLists({
				id
			}) {
				this.teamObj.page = 1;
				this.teamObj.limit = 8;
				this.teamObj.id = id;
				this.getUserCoupon();
			},
			async getUserCoupon(){
				this.showTeamList = true;
				this.teamObj.loading = true
				let [e, data] = await this.$api.userCoupon(this.teamObj);
				this.teamObj.loading = false
				if (e) return;
				if (data.code === 200) {
				
					this.teamList = data.data.data;
					this.teamListTotal = data.data.total;
				}
			},
			handleSelectionChange(val) {
				this.selectData = val;
			},
			//获取
			async couponList() {
				let [e, data] = await this.$api.couponList(this.searchForm);
				if (e) return;
				if (data.code === 200) {
					console.log(data.data);
					this.tableData = data.data.data;
					this.total = data.data.total;
					// this.allPrice = data.price
				}
			},

			//发布红包
			@formVerify('forms')
			async redCreate() {
				let [e, data] = await this.$api.couponAdd(this.editForm);
				if (e) return;
				if (data.code === 200) {

					this.$message.success(data.msg)
					this.couponList();
					this.showEdit = false;
				}
			},
			@formVerify('forms')
			async goodsSave() {
				let [e, data] = await this.$api.couponSave({
					id:this.editId,
					...this.editForm
				});
				if (e) return;
				if (data.code === 200) {

					this.$message.success(data.msg)
					this.couponList();
					this.showEdit = false;
				}
			},
			@delToast()
			async handDel({id}) {
			    let [e, data] = await this.$api.couponDel({id});
			    if (e) return;
			    if (data.code === 200) {
			        this.couponList();
			        this.$message.success('删除成功')
			    } else {
			        this.$message.warning(`${data.msg}`)
			    }
			},
			//导出
			async excelCourseVip() {
				this.$downFile('admin/theme/order/excel', this.searchForm, '课程购买列表');
			},

		}
	}
</script>
<style scoped lang="less">
	.page-content {
		width: calc(~'100% - 60px');
		margin: 26px 30px;
		box-sizing: border-box;
		background-color: #fff;
		border-radius: 4px;
		padding: 20px 30px;

		.park-form {
			border-bottom: 1px solid #efefef;
		}

		.table-content {
			padding-top: 20px;

			.table-top {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.table-top-text {
					font-size: 16px;
					font-weight: 400;
					color: rgba(35, 39, 52, 1);
				}

				.table-top-btn {}
			}
		}
	}

	.btn-content {
		display: flex;
		justify-content: center;
		align-items: center;

		.iconfont {
			font-size: 20px;
			color: rgb(190, 199, 213);
			margin: 0 10px;
			cursor: pointer;
		}

		.icon-shanchu {
			font-size: 24px;
		}
	}

	.box-shadow {
		width: calc(~'100% + 40px');
		height: 50px;
		position: absolute;
		top: 0;
		left: -20px;
		z-index: 10;
		box-shadow: 0 0 4px #b4b4b4;
	}

	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>
